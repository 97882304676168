import React from 'react';

import { UserContext } from '../providers/UserProvider';
import { getDeliveryWindowLabel, getFormattedTimeLabel } from '../deliveryWindows/scheduled-delivery-utils';
import { getEarliestOrderTimeMS, getLatestOrderTimeMS, slotDiscountForLocation } from './timeslot-utils';
import { dollarDisplay } from '../providers/OrderPricingProvider';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import PropTypes from 'prop-types';

import styles from './SelectTimeSlot.module.css';

// For debug visibility
const timeAttr = (timestamp) => {
  const d = new Date(timestamp);
  return d.toTimeString().split(" ")[0];
};

const SelectTimeSlot = ({
  dayIdx=0,
  slotIdx=0,
  displayDayIdx,
  timeSlots=[],
  currentTimeMS,
  handleSelect
}) => {

  const themeStyleChoose = {
    color: 'var(--zrPrimaryDark)',
    borderColor: 'var(--zrPrimaryDark)'
  };

  const themeStyleSelected = {
    color: '#fff',
    borderColor: 'var(--zrPrimaryDark)',
    backgroundColor: 'var(--zrPrimaryDark)'
  };

  const toggleIconStyle = {
    verticalAlign: -6,
    fontSize: 24
  };

  // Return selection to DisplayDeliverySlots
  const onSelect = (event) => {
    const slotIdx = parseInt(event.target.value, 10);
    handleSelect(slotIdx);
  }

  const [showAllClass, setShowAllClass] = React.useState('');
  const toggleShowAll = React.useCallback(() => {
    setShowAllClass(showAllClass === styles.showAll
      ? ''
      : styles.showAll
    );
  }, [showAllClass]);

  const { location } = React.useContext(UserContext);
  const { zip_code:user_zip_code } = location || {};

  return (
    <div className={`${styles.selectTimeSlot} ${showAllClass}`}>
      { timeSlots.length
        ? <>
            { displayDayIdx === 0 &&
              <>
                {/* We have expired slots */}
                { timeSlots[0].isExpired &&
                  <div className={styles.toggleBtn} onClick={toggleShowAll}>
                    <div className={styles.showMsg}>
                      Show unavailable delivery windows <AddRoundedIcon style={toggleIconStyle} />
                    </div>
                    <div className={styles.hideMsg}>
                      Hide unavailable delivery windows <RemoveRoundedIcon style={toggleIconStyle} />
                    </div>
                  </div>
                }
                {/* We have no same-day slots */}
                { timeSlots[timeSlots.length - 1].isExpired &&
                  <div className={styles.noWindowsMsg}>
                    Sorry, there are no same-day delivery windows&nbsp;available...
                  </div>
                }
              </>
            }
            { timeSlots.map((slot,idx) => {
              const slotCloseMS = getLatestOrderTimeMS(displayDayIdx, slot, currentTimeMS);
              // Moved to useCurrentTimeSlots:
              // const isExpired = slotCloseMS <= currentTimeMS;
              const slotOpenMS = getEarliestOrderTimeMS(displayDayIdx, slot, currentTimeMS);
              const isOpen = currentTimeMS >= slotOpenMS;
              const isExcludedZipCode = slot.exclude_zip_codes?.includes(user_zip_code);

              const openAtDate = new Date(slotOpenMS);
              const todaysDate = new Date(currentTimeMS);
              const willOpenToday = openAtDate.getDay() === todaysDate.getDay();

              const discountCents = slotDiscountForLocation(slot.incentive, location);

              const radioId = `day_${displayDayIdx}_slot_${idx}`;
              const isChecked = displayDayIdx === dayIdx && idx === slotIdx;
              const isDisabled = slot.isExpired || !isOpen || isExcludedZipCode;
              const slotLabel = getDeliveryWindowLabel(slot.hours);
              const selectedStyle = isChecked ? styles.selected : '';
              const disabledStyle = isDisabled ? styles.disabledFldSet : '';

              return (
                <fieldset key={radioId}
                          className={`${styles.fldSet} ${selectedStyle} ${disabledStyle}`}
                          data-closes-at={timeAttr(slotCloseMS)}
                          data-is-expired={slot.isExpired}>
                  <input type="radio" name="pickupSlot" id={radioId} value={idx}
                         onChange={onSelect} checked={isChecked}
                         disabled={isDisabled} />
                  <label htmlFor={radioId}>
                    {slotLabel}
                    <span className={styles.chooseButton} style={themeStyleChoose}>Choose</span>
                    <em style={themeStyleSelected}>Selected</em>
                    <span className={styles.notAvailable}>
                      { isExcludedZipCode
                        ? <>Sorry... <span className={styles.soldOut}>Sold Out!</span></>
                        :  displayDayIdx === 0
                          ? willOpenToday
                            ? <>Available at {getFormattedTimeLabel(openAtDate.getHours(), openAtDate.getMinutes())}</>
                            : <>No Longer Available</>
                          : <>Not Yet Available</>
                      }
                    </span>
                    { discountCents > 0 &&
                      <span className={styles.discount}>
                        {`Save ${dollarDisplay(discountCents, true)}!`}
                      </span>
                    }
                  </label>
                </fieldset>
              )
            })}
          </>
        : null
      }
    </div>
  )
}

SelectTimeSlot.propTypes = {
  dayIdx: PropTypes.number.isRequired,
  slotIdx: PropTypes.number.isRequired,
  displayDayIdx: PropTypes.number.isRequired,
  timeSlots: PropTypes.array.isRequired,
  currentTimeMS: PropTypes.number.isRequired,
  handleSelect: PropTypes.func.isRequired
};

export default SelectTimeSlot;
