import React from "react";

import config from "../../config";
import useAddlContextTracking, { TRACK_CONTEXT } from "../analytics/useAddlContextTracking";
import { useAnalyticsStore } from "../../App";
import { SIGN_IN_TEXT } from "./SignIn";
import useDefaultPhoneVerifyMethod from "./useDefaultPhoneVerifyMethod";
import { isValidPassword, isValidEmail } from './validation-utils';
import { getStoredValue, userAddressKey } from '../util/storage-utils';
import { checkEmailAvailability, checkPasswordStrength } from './registration-utils';
import { errMsg } from './registration-errors';
import { RegistrationContext } from "./RegistrationProvider";
import Header, { HeaderSize } from "../styleguide/Header";
import ErrorMessage from '../styleguide/ErrorMessage';
import BackLink from "../common/BackLink";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import VisibilityButton from "../common/VisibilityButton";
import AddressSearchAndUpdate from './AddressSearchAndUpdate';
import PhoneNumberInline from "./PhoneNumberInline";
import PhotoIdInline from "./PhotoIdInline";
import DisplaySignUpReferrer from "../refer/DisplaySignUpReferrer";
import VerifyPhoneAndComplete from "./VerifyPhoneAndComplete";
import DisplayModal from "../common/DisplayModal";
import ZypRunPrivacyPolicy from '../../docs/ZypRunPrivacyPolicy';
import ZypRunTermsOfService from '../../docs/ZypRunTermsOfService';
import InputMask from '@mona-health/react-input-mask'; // previously: "react-input-mask", v2.0.4 (findDomNode errors)

import PropTypes from 'prop-types';

/* Material UI overrides */
import './MaterialUI.module.css';
import styles from './SignUpInline.module.css';

const SignUpInline = ({
  onSubmit,
  onCancel,
  onSwitchToSignIn,
  cancelLinkText='Back',
}) => {

  const {
    name,
    setName,
    nameErr,
    setNameErr,
    email,
    setEmail,
    emailErr,
    setEmailErr,  /* needed for onBlur email-exists check */
    password,
    setPassword,
    passwordErr,
    setPasswordErr,  /* needed for onBlur strong-password check */
    location,
    setLocation,
    addressErr,
    apartmentNumber,
    setApartmentNumber,
    birthDate,
    setBirthDate,
    birthDateErr,
    setBirthDateErr,
    phoneNumber,
    setPhoneNumber,
    phoneNumberErr,
    setPhoneNumberErr,
    primaryPhotoId,
    setPrimaryPhotoId,
    primaryPhotoIdErr,
    setPrimaryPhotoIdErr,
    acceptTerms,
    setAcceptTerms,
    acceptTermsErr,
    setAcceptTermsErr,
    acceptMarketing,
    setAcceptMarketing,
    validationErrors,
    setValidationErrors
  } = React.useContext(RegistrationContext);

  const { trackEventWithContext } = useAddlContextTracking();

  // Check for stored address ( e.g. from marketing site)
  const storedAddress = React.useRef(getStoredValue(userAddressKey));
  // Check for voice default via Admin app setting
  const { isVoiceDefault } = useDefaultPhoneVerifyMethod();

  React.useEffect(() => {
    if (storedAddress.current && !location.street_address) {
      setLocation(JSON.parse(storedAddress.current));
    }
  }, [location, setLocation]);

  const [showTerms, setShowTerms] = React.useState();
  const [showPrivacy, setShowPrivacy] = React.useState();
  // Toggle password
  const [showPassword, setShowPassword] = React.useState();

  const { trackTestEvent } = useAnalyticsStore();
  // Validate these onBlur:
  const [emailAvailable, setEmailAvailable] = React.useState();
  const [strongPassword, setIsStrongPassword] = React.useState();

  const handleEmailOnBlur = React.useCallback((emailAvailable) => {
    // valid email
    setEmailErr(!isValidEmail(email));
    // email available
    setEmailAvailable(emailAvailable);
    // Track order - moved from PlaceOrder
    trackEventWithContext('registration_email_entered', [TRACK_CONTEXT.DEVICE, TRACK_CONTEXT.PARTNER]);
    trackTestEvent('registration_email_entered');
  }, [trackTestEvent, trackEventWithContext, email, setEmailErr]);

  const handlePasswordOnBlur = (isStrongPassword) => {
    setIsStrongPassword(isStrongPassword);
    setPasswordErr(!isStrongPassword);
  }

  const getPasswordErrorMsg = (password) => {
    if (isValidPassword(password)) {
      return '';
    } else if (password.length < 8) {
      return '\u26A0 Minimum length: 8 characters';
    } else {
      return '\u26A0 Uppercase, lowercase + number';  // Keep short
    }
  };

  /**
   * @param {*} event
   * @param {func} input value setter
   * @param {func} input value Error setter
   */
  const onChange = (event, setter, errorSetter) => {
    const { id, value, checked } = event.currentTarget;
    const inputValue = event.currentTarget.type === "checkbox"
          ? checked
          : value;
    if (setter) {
      setter(inputValue);
    }
    if (errorSetter) {
      errorSetter(false);
    }
    // reset email  error
    if (id === "email") {
      setEmailAvailable(null);
    }
    // Reset any validation errors
    setValidationErrors(false);
  };

  /* Material UI TextField Variant */
  const tF = 'filled';

  return (
    <div className={styles.inlineRegFlow}>
      <div className={styles.topLinks}>
        { onCancel &&
          <BackLink backToText={cancelLinkText} onClick={onCancel} />
        }
        { onSwitchToSignIn &&
          <span className={styles.signInOption}>
            Have an account?
            <span className={styles.signInLink} onClick={onSwitchToSignIn}>{SIGN_IN_TEXT}</span>
          </span>
        }
      </div>
      <Header size={HeaderSize.Large} isCentered text="Sign Up">
        <div className={styles.uniqueNote}>
          New accounts require a unique email and phone&nbsp;number.
        </div>
      </Header>
      {/* Name */}
      <TextField id="name" value={name} label="Full Name" error={nameErr} helperText={errMsg('name',nameErr)}
                 autoFocus variant={tF} onChange={(event)=>onChange(event, setName, setNameErr)} />

      {/* Display email availability notice after value is set true/false */}
      <div className={styles.inputFeedback}>
        { emailAvailable === true && !emailErr &&
          <div className={styles.inputSuccess}>&#10003; Account available</div>
        }
        { emailAvailable === false &&
          <div className={styles.inputFailure}>&#9888; An account exists for this email address</div>
        }
      </div>

      {/* Email */}
      <TextField id="email" type="email" value={email} label="Email" error={emailErr} helperText={errMsg('email', emailErr)}
                 onBlur={() => checkEmailAvailability(email, emailAvailable => handleEmailOnBlur(emailAvailable))}
                 variant={tF} onChange={(event)=>onChange(event, setEmail, setEmailErr)} />

      {/* Display password strength notice after value is set true/false */}
      <div className={styles.inputFeedback}>
        { strongPassword === true &&
          <div className={`${styles.inputSuccess} ${styles.inputSuccessPW}`}>&#10003; Strong password</div>
        }
        { strongPassword === false &&
          <div className={`${styles.inputFailure} ${styles.inputFailurePW}`}>{ getPasswordErrorMsg(password) }</div>
        }
      </div>

      {/* Password */}
      <div className={styles.passwordWrap}>
        <TextField id="password" type={showPassword ? 'text' : 'password'}  value={password}
          error={passwordErr} helperText={errMsg('password',passwordErr)}
          onBlur={() => checkPasswordStrength(password, isStrongPassword => handlePasswordOnBlur(isStrongPassword))}
          label="Password" variant={tF} onChange={(event)=>onChange(event, setPassword, setPasswordErr)} />
        <VisibilityButton
          withClass={styles.showPassword}
          visible={showPassword}
          onClick={() => setShowPassword(!showPassword)} />
      </div>

      <div style={{margin:'0 0 1rem'}}>
        <AddressSearchAndUpdate
          location={location}
          addressErr={addressErr}
          setLocation={setLocation} />
      </div>

      <div className={styles.inputPair}>
        {/* Apartment */}
        <TextField id="aptNumber" value={apartmentNumber} label="Apartment Number (optional)"
                   variant={tF} onChange={(event)=>onChange(event, setApartmentNumber)} />

        {/* DOB - there is currently a findDOMNode error coming from react-input-mask */}
        <InputMask mask="99/99/9999" value={birthDate} onChange={(event)=>onChange(event, setBirthDate, setBirthDateErr)}>
          <TextField id="birthDate" autoComplete="off" variant={tF} inputProps={{inputMode:'numeric'}}
                     label="Date of Birth (MM/DD/YYYY)" error={birthDateErr}
                     helperText={errMsg('birthDate', birthDateErr)} />
        </InputMask>
      </div>

      <PhoneNumberInline
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        phoneNumberErr={phoneNumberErr}
        setPhoneNumberErr={setPhoneNumberErr}
      />

      {/* obfuscated email used in filename */}
      { email && config.ENABLE_ID_UPLOAD
        ? <PhotoIdInline
            email={email}
            primaryPhotoId={primaryPhotoId}
            setPrimaryPhotoId={setPrimaryPhotoId}
            primaryPhotoIdErr={primaryPhotoIdErr}
            setPrimaryPhotoIdErr={setPrimaryPhotoIdErr}
            setValidationErrors={setValidationErrors}
          />
        : <></>
      }

      <div id="terms" className={styles.termsBox}>
        <FormControlLabel
          control={<Checkbox
            color="default"
            id="acceptTerms"
            checked={acceptTerms || false}
            onChange={(event)=>onChange(event, setAcceptTerms, setAcceptTermsErr)} />}
            label={<>I hereby agree to the Zyp Run
            {' '}<span className={styles.contentLink} onClick={(e) => { e.stopPropagation();  e.preventDefault(); setShowTerms(true)}}>Terms&nbsp;of&nbsp;Service</span>
            {' '}and
            {' '}<span className={styles.contentLink} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setShowPrivacy(true)}}>Privacy&nbsp;Policy</span></>}
        />
      </div>

      { acceptTermsErr && (
        <div className={styles.termsError}>
          { errMsg('accept_terms', true) }
        </div>
      )}

      <div className={styles.termsBox}>
        <FormControlLabel
          control={<Checkbox
            color="default"
            id="acceptMarketing"
            checked={acceptMarketing || false}
            onChange={(event)=>onChange(event, setAcceptMarketing)} />}
          label="Yes, send me updates and promotions via email or SMS message (you can update this in your account settings)"
        />
      </div>

      { config.ENABLE_CREDITS &&
        <DisplaySignUpReferrer />
      }

      {/* Validate form data, verify phone, then submit */}
      <VerifyPhoneAndComplete
        onSuccess={onSubmit}
        isVoiceDefault={isVoiceDefault} />

      {/* Errors resulting from full validation done in VerifyPhoneAndComplete */}
      { validationErrors &&
        <ErrorMessage text="Please correct your registration entries to continue." />
      }

      { showTerms &&
        <DisplayModal trackContext="terms" scrollingInner title="Terms of Service" closeFn={() => setShowTerms(false)}>
          <ZypRunTermsOfService />
        </DisplayModal>
      }

      { showPrivacy &&
        <DisplayModal trackContext="privacy" scrollingInner title="Zyp Run Privacy Policy" closeFn={() => setShowPrivacy(false)}>
          <ZypRunPrivacyPolicy />
        </DisplayModal>
      }
    </div>
  );
};

SignUpInline.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSwitchToSignIn: PropTypes.func,
  cancelLinkText: PropTypes.string
}

export default SignUpInline;
