import * as React from 'react';

import { trackEvent } from './tracking';
import { UserContext } from '../providers/UserProvider';
import { PartnerContext } from '../brand/PartnerProvider';
import { regFlowSuccessKey, getStoredValue } from '../util/storage-utils';

const ONE_DAY_MS = 86400000;

// Unregistered or registered less than 24 hours ago
const newlyRegistered = () => {
  const nowMS = new Date().getTime();
  // timestamp or legacy: true
  const regDateMS = getStoredValue(regFlowSuccessKey);
  return regDateMS
    ? typeof regDateMS === "number"
      ? nowMS - regDateMS < ONE_DAY_MS
      : false // legacy boolean
    : true // unregistered
}

export const TRACK_CONTEXT = {
  NEW_USER: 'new_user',
  DEVICE: 'device',
  PARTNER: 'partner',
}

const useAddlContextTracking = () => {

  // New user tracking
  const { hasCompletedFirstOrder } = React.useContext(UserContext);
  // Url path: /brand/{foo} matches partner.name from API to determine partner context
  const { partnerReferralDomain:partnerName } = React.useContext(PartnerContext);

  const trackEventWithContext = React.useCallback((label, context=[]) => {
    // Handle string or array context param
    const contextArray = Array.isArray(context) ? context : [context];
    // Track the original/vanilla event
    trackEvent(label);
    // Track the additional contexts
    // New user/same day checkout flow
    if (contextArray.includes(TRACK_CONTEXT.NEW_USER)) {
      if(!hasCompletedFirstOrder && newlyRegistered()) {
         trackEvent(`new_user_${label}`);
      }
    }
    if (contextArray.includes(TRACK_CONTEXT.DEVICE)) {
      trackEvent(`${label}_${window.screen.width > 480 ? 'dt' : 'mb'}`);
    }
    if (contextArray.includes(TRACK_CONTEXT.PARTNER)) {
      if (partnerName) {
        trackEvent(`${label}_${partnerName}`);
      }
    }
  }, [hasCompletedFirstOrder, partnerName])

  return {
    trackEventWithContext,
  }
}

export default useAddlContextTracking;
