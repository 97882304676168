/* TEST DATA in case I need it */
export const dailyHoursDefault = {start_hour:10, start_minute:0, end_hour:7, end_minute:45};

export const defaultHoursOfOperation = [
  { weekday: "monday", hours: dailyHoursDefault},
  { weekday: "tuesday", hours: dailyHoursDefault},
  { weekday: "wednesday", hours: dailyHoursDefault},
  { weekday: "thursday", hours: dailyHoursDefault},
  { weekday: "friday", hours: dailyHoursDefault},
  { weekday: "saturday", hours: dailyHoursDefault},
  { weekday: "sunday", hours: dailyHoursDefault}
];

// We need to evaluate whether to update redux ( when user's selection has changed ).
const FAST_FLEXIBLE_DETAILS = {
  "start_hour": -1,
  "start_minute": 0,
  "end_hour": -1,
  "end_minute": 0
};

/**
 * The method allows altering the timeStamp for testing purposes
 *
 * @param {number} timeStamp
 */
const getCompareDate = (timeStamp) => {
  // adjust for testing
  const timeShiftMS = 0; // 6*60*60*1000; // 6 hours
  return new Date(timeStamp + timeShiftMS);
};

/**
 * Convert dispensary business hours to display strings: 14, 15 -> 2:15pm
 *
 * @param {number} hour
 * @param {number} minute
 */
export const getFormattedTimeLabel = (hour, minute) => {
  const date = new Date();
  date.setHours(hour);
  date.setMinutes(minute);
  const formatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  };
  return date.toLocaleString('en-US', formatOptions).toLocaleLowerCase().replace(' ', ''); // '\u00A0'
};

/**
 * Convert hour number to display string: 13 => "1pm"
 *
 * @param {number} hour
 */
export const getFormattedHourLabel = (hour) => {
  const date = new Date();
  date.setHours(hour);
  date.setMinutes(0);
  const formatOptions = {
    hour: 'numeric',
    hour12: true
  };
  // 9 PM => 9pm
  return date.toLocaleString('en-US', formatOptions).toLocaleLowerCase().replace(' ', '');
};

/**
 * Get the formatted delivery window label: e.g. 9:00 am - 11:00 am
 *
 * @param {object} deliveryWindow - a single window returned by the API
 */
export const getDeliveryWindowLabel = (deliveryWindow) => {
  const { start_hour, start_minute, end_hour, end_minute } = deliveryWindow;
  const windowStart = getFormattedTimeLabel(start_hour, start_minute);
  const windowEnd = getFormattedTimeLabel(end_hour, end_minute);
  return `${windowStart} - ${windowEnd}`;
};

/**
 * Build the delivery window labels for a single day
 *
 * @param {array} deliveryWindows
 */
const buildWindows = (deliveryWindows) => {
  const windowInfo = [{label:'Fast & Flexible', start_hour: -1}];  /* enabled for current day only */
  deliveryWindows.forEach((deliveryWindow) => {
    windowInfo.push({
      start_hour: deliveryWindow.start_hour,
      label: getDeliveryWindowLabel(deliveryWindow)
    });
  });
  return windowInfo;
}

/**
 * Return day, month year json
 *
 * @param {number} offset - number of days in future
 * @param {Date} serverDate - Todays Date created from timestamp
 */
const buildDeliveryDate = (offset, serverDate) => {
  const buildDate = new Date(serverDate.setDate(serverDate.getDate() + offset));
  return {
    day: buildDate.getDate(),
    month: buildDate.getMonth() + 1,
    year: buildDate.getFullYear()
  };
}

/**
 * Build a map containing th business hours and the various labels for each day
 *
 * @param {array} weeklyDeliveryWindows - Dispensary hours for each day of the week.
 * @param {number} timeStamp - A current serer timeStamp from the API
 */
export const buildDeliveryWindowMap = (weeklyDeliveryWindows, timeStamp) => {
  const serverDate = getCompareDate(timeStamp);
  if (weeklyDeliveryWindows && weeklyDeliveryWindows.length) {
    // First build the 7 day map
    const fullDeliveryWindowMap = new Map();
    weeklyDeliveryWindows.forEach((option) => {
      // API is One-based and 1 is Monday, so let's make Sunday idx=0 for JS compatability
      let dayIdx = parseInt(option.iso_weekday,10);
      if (dayIdx === 7) {
        dayIdx = 0;
      }
      // Capitalize the day: Monday!
      const dayName = option.weekday.charAt(0).toUpperCase() + option.weekday.slice(1);

      // Details needed for each day
      const windowsForDay = {
        dayName,
        dayNameShort: dayName.substring(0,3).toUpperCase(), // Tue
        deliveryWindows: buildWindows(option.delivery_windows), // For display
        windowDetails: [FAST_FLEXIBLE_DETAILS, ...option.delivery_windows] // Raw data
      };
      fullDeliveryWindowMap.set(dayIdx, windowsForDay);
    });

    // Now collect the windows for today plus 4 days (e.g. 2,3,4,5,6 if today is Tuesday)
    const today = serverDate.getDay();
    const desiredRange = [
      today,
      (today + 1) % 7,
      (today + 2) % 7,
      (today + 3) % 7,
      (today + 4) % 7
    ];
    const deliveryWindowSubset = new Map();
    desiredRange.forEach((dayIdx, idx) => {
      const displayVals = fullDeliveryWindowMap.get(dayIdx);
      deliveryWindowSubset.set(idx, {
        ...displayVals,
        deliveryDate: buildDeliveryDate(idx, serverDate) // Raw Date json submitted with order
      });
    });
    return deliveryWindowSubset;
  }
  return null;
};

/**
 * Check if s specific window is disabled (in the past)
 *
 * This is also used in display when viewing different days
 *
 * @param {number} dayIdx
 * @param {number} slotIdx
 * @param {Map} deliveryWindowMap
 * @param {number} orderLeadMinutes
 * @param {number} timeStamp
 */
export const isDisabledWindow = (dayIdx, windowIdx, deliveryWindowMap, orderLeadMinutes, timeStamp) => {
  if (deliveryWindowMap) {
    // TODO: validate with server value
    const dateToCompare = getCompareDate(timeStamp); // new Date(timeStamp + (2*60*60*1000));   // - 8 hours
    const currentDay = dateToCompare.getDay();  // 0 - 6
    // Set time ahead based on lead time requirement
    dateToCompare.setMinutes(dateToCompare.getMinutes() + orderLeadMinutes);
    /**
     *  Order lead time at 11:30pm can push us into new day so make note of new
     *  day for start_hour comparisons.
     */
    const pastMidnight = currentDay !== dateToCompare.getDay();
    const { deliveryWindows } = deliveryWindowMap.get(dayIdx);
    const deliveryWindow = deliveryWindows[windowIdx];
    /**
     * Disable Fast & Flexible if there's only one same-day window available
     * Fast & Flexible is window 0, real windows start at 1
     */
    const secondToLastWindow = deliveryWindows[deliveryWindows.length - 2];
    return dayIdx > 0
      // Enable all windows for days in the future
      ? false
      // Fast & Flexible
      : pastMidnight // after adjusting for lead time
        ? true
        : deliveryWindow.start_hour === -1
          ? dateToCompare.getHours() >= secondToLastWindow.start_hour
          : dateToCompare.getHours() >= deliveryWindow.start_hour;
  }
  return false;
};

/**
 * If there are no more same-day delivery windows we default to tomorrow
 *
 * @param {*} deliveryWindowMap
 * @param {*} orderLeadMinutes
 * @param {number} timeStamp
 */
export const getDefaultDeliveryDate = (deliveryWindowMap, orderLeadMinutes, timeStamp) => {
  // get last delivery window index
  const lastWindowIdx = deliveryWindowMap.get(0).deliveryWindows.length - 1;
  return isDisabledWindow(0,lastWindowIdx,deliveryWindowMap,orderLeadMinutes,timeStamp) ? 1 : 0;
};

/**
 * The default window is Fast & Flexible except when:
 *   a) There's only one same-day window available
 *   b) There are no same-day windows available
 *
 * @param {*} deliveryWindowMap
 * @param {*} orderLeadMinutes
 * @param {*} timeStamp
 */
export const getDefaultWindow = (deliveryWindowMap, orderLeadMinutes, timeStamp) => {
  if (deliveryWindowMap) {
    const lastWindowIdx = deliveryWindowMap.get(0).deliveryWindows.length - 1;
    const secondToLastWindowIdx = deliveryWindowMap.get(0).deliveryWindows.length - 2;

    return getDefaultDeliveryDate(deliveryWindowMap, orderLeadMinutes, timeStamp) === 1
      // Delivery is tomorrow so window = 1 (0 is Fast & Flexible)
      ? 1
      : isDisabledWindow(0,secondToLastWindowIdx,deliveryWindowMap, orderLeadMinutes, timeStamp)
        // Only one same-day window available, window = last (no Fast & Flexible)
        ? lastWindowIdx
        // Fast & Flexible
        : 0;
  }
  return 0;
};

// Fast & Flexible is disabled if there's only one same-day window available
export const getSecondToLastWindowIdx = (dayIdx, deliveryWindowMap) => {
  const { deliveryWindows } = deliveryWindowMap.get(dayIdx);
  return deliveryWindows.length - 2;
};

/**
 * Fast & Flexible is disabled if there's only one delivery window
 *
 * @param {Map} deliveryWindowMap
 * @param {number} orderLeadMinutes
 * @param {number} timeStamp
 */
export const isFlexibleDisabled = (deliveryWindowMap, orderLeadMinutes, timeStamp) => {
  // Get same-day delivery windows
  const { deliveryWindows } = deliveryWindowMap.get(0);
  // Check second-to-last delivery window
  return isDisabledWindow(0, deliveryWindows.length - 2, deliveryWindowMap, orderLeadMinutes, timeStamp);
};

/**
 * Fast & Flexible currently uses the first available window
 *
 * Note: Will NOT return next-day window
 *
 * @param {*} deliveryWindowMap
 * @param {*} orderLeadMinutes
 * @param {*} timeStamp
 */
const getFirstAvailableWindowIdx = (deliveryWindowMap, orderLeadMinutes, timeStamp) => {
  if (deliveryWindowMap) {
    // Get current hour adjusted for orderLeadMinutes
    const today = getCompareDate(timeStamp);
    today.setMinutes(today.getMinutes() + orderLeadMinutes);
    const hourWithLeadTime = today.getHours();
    // Get all todays delivery windows
    const todaysWindows = deliveryWindowMap.get(0);
    let ffIdx = 0;
    // Return first eligible window
    while (hourWithLeadTime >= todaysWindows.deliveryWindows[ffIdx].start_hour &&
           ffIdx < todaysWindows.deliveryWindows.length - 1) {
      ffIdx++;
    }
    return hourWithLeadTime < todaysWindows.deliveryWindows[ffIdx].start_hour
           ? ffIdx
           : 0;
  }
  return 0;
};

/**
 * Fast & Flexible currently uses the first available window
 *
 * Note: Will NOT return next-day window
 *
 * @param {*} deliveryWindowMap
 * @param {*} orderLeadMinutes
 * @param {*} timeStamp
 */
export const getFastAndFlexibleWindow = (deliveryWindowMap, orderLeadMinutes, timeStamp) => {
  if (deliveryWindowMap) {
    const fastFlexibleIdx = getFirstAvailableWindowIdx(deliveryWindowMap, orderLeadMinutes, timeStamp);
    return deliveryWindowMap.get(0).windowDetails[fastFlexibleIdx];
  }
  return null;
};

const futureDate = (timeStamp, daysToAdd) => {
  const today = getCompareDate(timeStamp);
  return new Date(today.setDate(today.getDate() + daysToAdd));
};

// Using the selection offsets, build the delivery window details expected by the API
export const buildOrderDeliveryDetails = (dayIdx, windowIdx, deliveryWindowMap, orderLeadMinutes, timeStamp) => {
  // build the calendar date from the offset
  const deliveryDate = futureDate(timeStamp, dayIdx);
  // Account for Fast & Flexible which is day 0, window 0;
  let deliveryWindow;
  if (dayIdx === 0 && windowIdx === 0) {
    deliveryWindow = getFastAndFlexibleWindow(deliveryWindowMap, orderLeadMinutes, timeStamp);
  } else {
    deliveryWindow = deliveryWindowMap.get(dayIdx).windowDetails[windowIdx];
  }
  const deliverySchedule = {
    day: deliveryDate.getDate(),
    month: deliveryDate.getMonth() + 1,
    year: deliveryDate.getFullYear(),
    window: deliveryWindow
  };
  return deliverySchedule;
};

// Today, MON, TUE, etc.
export const getDayLabel = (offset, deliveryWindowMap) => {
  if (offset === 0) {
    return 'Today';
  }
  return deliveryWindowMap
         ? deliveryWindowMap.get(offset).dayNameShort
         : '';
};

// Monday, Tuesday, etc.
export const getFullDayLabel = (offset, deliveryWindowMap) => {
  if (offset === 0) {
    return 'Today';
  }
  return deliveryWindowMap
         ? deliveryWindowMap.get(offset).dayName
         : '';
};

// Display: Dec 2, Dec 3, etc.
export const getOrderDate = (offset, timeStamp) => {
  const today = getCompareDate(timeStamp);
  today.setDate(today.getDate() + offset);
  const dateInfo = today.toDateString().split(' ');
  return `${dateInfo[1]} ${parseInt(dateInfo[2])}`;
};