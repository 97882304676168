/**
 * Note: Prod/Dev Authentication is configured in Firebase.js
 *
 * PROD- https://ziprun-prod.uc.r.appspot.com/api/v1
 * QA1- https://ziprun-qa1.uc.r.appspot.com/api/v1
 * QA2- https://ziprun-qa2.uk.r.appspot.com/api/v1
 */

// staging = QA1/QA2 sites
const isStagingBuild = typeof process.env.REACT_APP_STAGING_BUILD !== 'undefined' &&
                              process.env.REACT_APP_STAGING_BUILD === 'true';

// QA APP DOMAINS
const app_domain_qa1 = "https://zyprun-qa1-shop.web.app";
const app_domain_qa2 = "https://zyprun-qa2-shop.web.app";
// QA API DOMAINS
const api_domain_qa1 = 'https://ziprun-qa1.uc.r.appspot.com';
const api_domain_qa2 = 'https://ziprun-qa2.uk.r.appspot.com';
// QA MAP KEYS
const google_maps_key_qa1 = 'AIzaSyDOxPRDg4rKJnjJK2IuBeLW90PfJ6CKgpU';
const google_maps_key_qa2 = 'AIzaSyAZlGK5Q01ILCn4E8ypjGbgMT9yN87WaG0';

// Default for localhost (qa1/qa2)
const dev_qa_server = 2;

let app_domain_qa = dev_qa_server === 1 ? app_domain_qa1 : app_domain_qa2;
let api_domain_qa = dev_qa_server === 1 ? api_domain_qa1 : api_domain_qa2;
let google_maps_key_qa = dev_qa_server === 1 ? google_maps_key_qa1 : google_maps_key_qa2;

if (process.env.PUBLIC_URL && process.env.PUBLIC_URL.indexOf('-qa1') > -1) {
  app_domain_qa = app_domain_qa1;
  api_domain_qa = api_domain_qa1;
  google_maps_key_qa = google_maps_key_qa1;
}

const development = {
  REACT_APP_APP_DOMAIN: app_domain_qa,
  REACT_APP_API_DOMAIN: api_domain_qa,
  GOOGLE_MAPS_KEY: google_maps_key_qa,
  // yodlee script currently enabled in index.html!
  IS_AEROSYNC_DEV: false,
  AEROPAY_FORCE_BANK_LINKING: false, // for testing, ignore previous bank linking
  AEROPAY_ENV: 'staging',
  IMAGE_CDN_BASE: 'https://zyprun.com/cdn-cgi/image',
  ENABLE_CLOUDFLARE_CDN: true,
  ENABLE_PROMO_TEXT: false,
  IS_DEV: true,
  ENABLE_ID_UPLOAD: true,
  ENABLE_CREDITS: true,
  ENABLE_AEROPAY_CREDIT: false,
  ENABLE_TESTS: false,
  ZYP_RUN_DISPENSARY_ID: 'zr-warehouse-1',
}

const production = {
  REACT_APP_APP_DOMAIN: 'https://shop.zyprun.com',
  REACT_APP_API_DOMAIN: 'https://ziprun-prod.uc.r.appspot.com',
  GOOGLE_MAPS_KEY: 'AIzaSyAFzrir5dvNfpY9EOFKUBIcxXpJVly-nVI', /* no localhost support */
  // yodlee script currently enabled in index.html!
  IS_AEROSYNC_DEV: false,
  AEROPAY_FORCE_BANK_LINKING: false, // for testing only!, ignore previous bank linking
  AEROPAY_ENV: 'production',
  IMAGE_CDN_BASE: 'https://zyprun.com/cdn-cgi/image',
  ENABLE_CLOUDFLARE_CDN: true,
  ENABLE_PROMO_TEXT: false,
  IS_DEV: false,
  ENABLE_ID_UPLOAD: true,
  ENABLE_CREDITS: true,
  ENABLE_AEROPAY_CREDIT: false,
  ENABLE_TESTS: false,
  ZYP_RUN_DISPENSARY_ID: 'zr-warehouse-1',
}

// const config = production;
const config = process.env.NODE_ENV === 'development' || isStagingBuild ? development : production;

export default config;
