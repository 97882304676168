import * as React from 'react';

import { devLog } from '../util/util';
import { UserContext } from '../providers/UserProvider';
import useInterval from '../util/useInterval';
import usePageVisibility from '../util/usePageVisibility';
import { getFormattedTimeLabel } from '../deliveryWindows/scheduled-delivery-utils';
import { getDefaultSlotForToday, getNextDiscountSlotForToday, getSlotsStartingToday } from './timeslot-utils';

// Boston default when we have no user zip code
const WAREHOUSE_ZIP_CODE = '02122';

const useNextAvailableTimeSlot = (timeSlotsByDay) => {

  // Check for exclusions on user's zip code
  const { user, currentLocation } = React.useContext(UserContext);
  // Default time slot, -1 when unset or no slots for today
  const [defaultSlot, setDefaultSlot] = React.useState(-1);
  // For promoting an available discounted slot
  const [nextDiscountedSlot, setNextDiscountedSlot] = React.useState();
  // Slot label: 3pm, etc.
  const [slotLabel, setSlotLabel] = React.useState();
  // Timeslots ordered by current day of week
  const [displayTimeSlots, setDisplayTimeSlots] = React.useState();
  // Display fallback message for Boston location
  const [isBostonDefault, setIsBostonDefault] = React.useState();
  // Show clock icon
  const [isNextDay, setIsNextDay] = React.useState();

  const [tabVisible, setTabVisible] = React.useState();
  // Update Order Status immediately on tab activate
  usePageVisibility(visibilityState => {
    setTabVisible(visibilityState === "visible");
    if (visibilityState === "visible") {
      setCurrentTimeMS(new Date().getTime());
    }
  });

  // Run the slot check every 10 mins when browser tab is active
  const intervalMS = 10 * 60 * 1000;
  const [currentTimeMS, setCurrentTimeMS] = React.useState(new Date().getTime());
  useInterval(() => {
    devLog(`10 minute eta update at ${currentTimeMS}`);
    setCurrentTimeMS(new Date().getTime());
  }, intervalMS, tabVisible); // /* pause when tab hidden */

  const getSlotLabel = React.useCallback((defaultSlot, slotsStartingToday) => {
    if (typeof defaultSlot === 'number' && slotsStartingToday?.length) {
      // Handle next slot being tomorrow (-1)
      const dayIdx = defaultSlot === -1 ? 1 : 0;
      const slotIdx = defaultSlot === -1 ? 0 : defaultSlot;
      const slotsForDay = slotsStartingToday[dayIdx];
      const { hours } = slotsForDay[slotIdx];
      const label = getFormattedTimeLabel(hours.end_hour, hours.end_minute);
      devLog(`Current Slot ETA: ${label}`);
      return label;
    }
    return undefined;
  }, [])

  /**
   * Determine the next valid/available delivery timeslot based on currentTimeMS
   */
  React.useEffect(() => {
    const { zip_code:user_zip_code } = currentLocation || {};
    if (timeSlotsByDay) {
      // e.g. 2 for Tuesday
      const todayNum = new Date(currentTimeMS).getDay();
      const validSlots = getSlotsStartingToday(todayNum, timeSlotsByDay)
      setDisplayTimeSlots(validSlots);
      // Get first available slot or -1
      const defaultSlot = getDefaultSlotForToday(
        validSlots[0],
        currentTimeMS,
        user_zip_code || WAREHOUSE_ZIP_CODE);
      setDefaultSlot(defaultSlot);
      const discountedSlot = getNextDiscountSlotForToday(
        validSlots[0],
        currentTimeMS,
        user_zip_code
      );
      setNextDiscountedSlot(discountedSlot);
      setIsNextDay(defaultSlot === -1);
      setIsBostonDefault(user && user.isAnonymous && !user_zip_code);
      setSlotLabel(getSlotLabel(defaultSlot, validSlots))
      devLog('Update current slot ETA display');
    }
  }, [timeSlotsByDay, user, currentLocation,
      currentTimeMS, getSlotLabel]);

  return {
    defaultSlot,
    nextDiscountedSlot,
    slotLabel,
    isBostonDefault,
    isNextDay,
    displayTimeSlots,
  }
};

export default useNextAvailableTimeSlot;
