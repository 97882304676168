import * as React from 'react';
import { trackEvent } from '../analytics/tracking';
import PropTypes from 'prop-types';

const ChatLink = ({
  trackinglabel,
  children
}) => {

  React.useEffect(() => {
    if (trackinglabel) {
      trackEvent(`chat_link_${trackinglabel}_show`); 
    }
  }, [trackinglabel])

  const showChat = React.useCallback(() => {
    if (typeof window.Intercom !== "undefined") {
      window.Intercom('show');
    }
    if (trackinglabel) {
      trackEvent(`chat_link_${trackinglabel}_click`);
    }
  }, [trackinglabel])

  const linkStyle = {
    cursor: 'pointer',
    margin: '0 .25em',
    textDecoration: 'underline',
    font: 'var(--zrFontSemi)',
    fontSize: 'inherit',
    whiteSpace: 'nowrap',
    color: 'var(--zrPrimaryDark)',
  }

  return (
    <span style={linkStyle} onClick={showChat}>{children}</span>
  )
}

ChatLink.propTypes = {
  trackinglabel: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.array])
}

export default ChatLink;
