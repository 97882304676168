import React from 'react';

import { CartItemsContext } from '../checkout/CartItemsProvider';
import useMinOrderForLocation from '../dispensary/useMinOrderForLocation';
import { useAnalyticsStore } from '../../App';
import useAddlContextTracking, { TRACK_CONTEXT } from '../analytics/useAddlContextTracking';
import { navigateTop } from '../routing/router-utils';
import useDeliverability from '../dispensary/useDeliverability';
import DeliverabilityWarnings from './DeliverabilityWarnings';
import ButtonWithPrice from '../styleguide/ButtonWithPrice';
import DisabledButtonWithReason from '../styleguide/DisabledButtonWithReason';
import MinimumOrderWarning from '../dispensary/MinimumOrderWarning';
import PropTypes from 'prop-types';

import styles from './Cart.module.css';

/**
 * Calculate the total product price and display checkout button
 */
const CheckOutButton = ({closeMenuFn}) => {

  const { itemTotal, itemTotalDisplay } = React.useContext(CartItemsContext);
  const { minOrderCents } = useMinOrderForLocation();
  const { trackTestEvent } = useAnalyticsStore();
  const { trackEventWithContext } = useAddlContextTracking();

  const [minOrderMet, setMinOrderMet] = React.useState();

  React.useEffect(() => {
    if (itemTotal) {
      // Check minimum order
      setMinOrderMet(itemTotal >= minOrderCents);
    }
  }, [itemTotal, minOrderCents]);

  // User location or service in general
  const { isDeliverable } = useDeliverability();

  const proceedToCheckout = () => {
    // close mobile cart menu
    if ( closeMenuFn ) {
      closeMenuFn();
    }
    navigateTop('/checkOut/', { state: {
      returnToPath: `${window.location.pathname}`,
      continuePath: '/checkOut'
    }})
    trackEventWithContext('cart_checkout_click', [TRACK_CONTEXT.NEW_USER, TRACK_CONTEXT.DEVICE, TRACK_CONTEXT.PARTNER]);
    trackTestEvent('cart_checkout_click');
  };

  return(
    <div className={styles.toCheckoutForm}>
      { isDeliverable
        ? minOrderMet
          // Delivery requirements met
          ? <ButtonWithPrice isFullWidth text="Checkout" price={itemTotalDisplay} handleClick={proceedToCheckout} />
          : <>
              <ButtonWithPrice isFullWidth text="Checkout" price={itemTotalDisplay} disabled handleClick={(e) => { e.preventDefault() }} />
              <MinimumOrderWarning />
            </>
        : <DisabledButtonWithReason text="Checkout" isFullWidth
            reason={<DeliverabilityWarnings />} />
      }
    </div>
  )
}

CheckOutButton.propTypes = {
  closeMenuFn: PropTypes.func
};

export default CheckOutButton;
