import * as React from "react";

import ToolTip from "../common/ToolTip";
import { STACKING } from "../layout/stackingConstants";
import ownedAsianIcon from '../../assets/ownedAsianIcon.png';
import ownedBlackIcon from '../../assets/ownedBlackIcon.png';
import ownedLatinIcon from '../../assets/ownedLatinIcon.png';
import ownedLGBTQIcon from '../../assets/ownedLGBTQIcon.png';
import ownedSocialEquityIcon from '../../assets/ownedSocialEquityIcon.png';
import ownedWomanIcon from '../../assets/ownedWomanIcon.png';
import ownedVeteranIcon from '../../assets/ownedVeteranIcon.png';

const OWNER_TYPES = {
  'asian-owned-biz': {
    icon: ownedAsianIcon,
    title: 'Asian-Owned',
    text: ' is an asian-owned business'
  },
  'black-owned-biz': {
    icon: ownedBlackIcon,
    title: 'Black-Owned',
    text: ' is a black-owned business'
  },
  'latin-owned-biz': {
    icon: ownedLatinIcon,
    title: 'Latin-Owned',
    text: ' is a latin-owned business'
  },
  'lgbtq-owned-biz': {
    icon: ownedLGBTQIcon,
    title: 'LGBTQ+-Owned',
    text: ' is an LGBTQ+-owned business'
  },
  'social-equity-owned-biz': {
    icon: ownedSocialEquityIcon,
    title: 'Social Equity Business',
    text: ' is a social equity business'
  },
  'woman-owned-biz': {
    icon: ownedWomanIcon,
    title: 'Woman-Owned',
    text: ' is a woman-owned business'
  },
  'veteran-owned-biz': {
    icon: ownedVeteranIcon,
    title: 'Veteran-Owned',
    text: ' is a veteran-owned business'
  }
}

// Tag brands here (rather than on every product)
const TAGS_FOR_BRAND = {
  'Advanced Cultivators': [OWNER_TYPES['latin-owned-biz']],
  'Freshly Baked' : [OWNER_TYPES['black-owned-biz'], OWNER_TYPES['woman-owned-biz'], OWNER_TYPES['veteran-owned-biz']],
  'Harbor House': [OWNER_TYPES['latin-owned-biz']],
  'Kapnos Cannabis' : [OWNER_TYPES['woman-owned-biz']],
  'Major Bloom' : [OWNER_TYPES['black-owned-biz']],
  'RYTHM' : [OWNER_TYPES['veteran-owned-biz']],
  '@loudgirlcookies' : [OWNER_TYPES['woman-owned-biz']],
}

// hanndled via tag or as defined above
const BizOwnerTooltip = ({
  tags=[],
  businessName,
  iconHeight=20,
  inModal,
}) => {

  const [bizTypeTags, setBizTypeTags] = React.useState();

  React.useEffect(() => {
    // prevent dupes
    const tagged = [];
    // We'll let product tags override the hard-coded info
    const bizTags = tags.length
      ? tags.reduce((relevantTags, tag) => {
          const { tagName } = tag;
          if (tagName?.endsWith('-biz') &&
              OWNER_TYPES[tagName] &&
              !tagged.includes(tagName)) {
            relevantTags.push(OWNER_TYPES[tagName]);
            tagged.push(tagName);
          }
          return relevantTags;
        }, [])
      : [];
    setBizTypeTags(bizTags.length ? bizTags : TAGS_FOR_BRAND[businessName]);
  }, [tags, businessName]);

  const wrapperStyle = {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    marginLeft: 6,
  };

  return ( bizTypeTags?.length
    ? <div style={wrapperStyle}>
        { bizTypeTags.map(tag => (
          <ToolTip
            key={tag.title}
            buttonIcon={tag.icon}
            buttonIconHeight={iconHeight}
            tipTitle={tag.title}
            placement="bottom-start"
            inModal={inModal}
            enableFlip={true}
            buttonStyle={{verticalAlign:-5, zIndex:inModal ? STACKING.MODAL_TOOLTIP : 1, padding:'4px 2px'}}
            toolTipStyle={{zIndex:inModal ? STACKING.MODAL_TOOLTIP : 1}}>
              {`${businessName}${tag.text}`}
          </ToolTip>
        ))}
      </div>
    : null
  );
};

export default BizOwnerTooltip;
