import * as React from 'react';

import { navigateTop } from '../routing/router-utils';
import { UserContext } from '../providers/UserProvider';
import { NotificationsContext } from '../notifications/NotificationsProvider';
import { SHOPPING_MILESTONES } from '../analytics/analytics-utils';
import { CommerceLoggingContext } from '../analytics/CommerceLoggingProvider';
import useIntercomLogging, { INTERCOM_MILESTONES, INTERCOM_SEGMENT_KEYS, shouldLogOrderReferrer } from '../intercom/useIntercomLogging';
import { getIntercomPaymentAction } from '../providers/DeliveryOptionsProvider';
import { useAnalyticsStore } from '../../App';
import useAddlContextTracking, { TRACK_CONTEXT } from '../analytics/useAddlContextTracking';
import { trackEventByLocation } from '../analytics/tracking';
import { getFormattedAddress } from '../googlemaps/google-maps-utils';
import BodyWrapper, { LayoutTypes } from '../layout/BodyWrapper';
import PageTitle from '../common/PageTitle';
import GoogleMap from '../googlemaps/GoogleMap';
import Header, { HeaderSize } from '../styleguide/Header';
import DeliveryCustomerDetails from './DeliveryCustomerDetails';
import OrderItem from './OrderItem';
import ValidateOrderResults from '../debug/ValidateOrderResults';
import OrderDetails from './OrderDetails';
import WhatToExpect from './WhatToExpect';
import AdditionalCustomerAsks from './AdditionalCustomerAsks';
import Button from '../styleguide/Button';

import styles from './OrderConfirmation.module.css';

// 'location' is the router 'navigate' props (the order info)
const OrderConfirmation = ({
  location={}
}) => {

  const { trackTestEvent, trackError } = useAnalyticsStore();
  const { trackEventWithContext } = useAddlContextTracking();
  const { location:userLocation } = React.useContext(UserContext);
  const { refetchOrders } = React.useContext(NotificationsContext);
  const { logIntercomAction, logIntercomValue } = useIntercomLogging();
  const { logCommerceAction } = React.useContext(CommerceLoggingContext);
  const [order, setOrder] = React.useState();

  /* We need to force a re-render on window resize for the map */
  let resizeRef = React.useRef();
  const [resizeCount, setResizeCount] = React.useState(0);
  const mapHeight = window.innerWidth > 960 ? '100%' : '225px';

  // Differences between App and API cost calcs
  const { costCalcDiffs=[] } = location.state || {};

  // Set the order info
  React.useEffect(() => {
    const handleResize = () => {
      window.clearTimeout(resizeRef.current);
      resizeRef.current = window.setTimeout(() => setResizeCount(resizeCount + 1), 500);
    }
    window.addEventListener('resize', handleResize);

    const { referralDomain } = location.state;

    if (!order) {
      // orderInfo is API response data
      const orderInfo = location.state;
      if (orderInfo && orderInfo.id) {
        setOrder(orderInfo);
      }
      if (orderInfo?.costs?.subtotal) {
        logCommerceAction(SHOPPING_MILESTONES.ORDER_PLACED, orderInfo.costs.subtotal);
        logIntercomAction(INTERCOM_MILESTONES.ORDER_DELIVERY);
        // Log payment method
        logIntercomAction(getIntercomPaymentAction(orderInfo));
        // Log referrer if not google or zyprun
        if (shouldLogOrderReferrer(referralDomain)) {
          logIntercomValue(INTERCOM_SEGMENT_KEYS.ORDER_REFERRER,referralDomain);
        }
        // Track Aeropay signup credits
        if (orderInfo.costs.aeropay_use_discount > 0) {
          logIntercomAction(INTERCOM_MILESTONES.AEROPAY_SIGNUP_CREDIT);
        }
        // Track order - moved from PlaceOrder
        trackEventWithContext('order_delivery_success', [TRACK_CONTEXT.NEW_USER, TRACK_CONTEXT.DEVICE, TRACK_CONTEXT.PARTNER]);
        // Only fires if tested enabled
        trackTestEvent('order_delivery_success');
        // Track order city, skipping the analytics store for this
        trackEventByLocation('order_delivery', orderInfo.customer?.location || {});
        // Start order progress indicator
        refetchOrders();
      } else {
        trackError('error_order_confirmation_subtotal');
      }
    }
    return  () => window.removeEventListener('resize', handleResize);
  },[trackError, trackEventWithContext, trackTestEvent,
     location.state, order, resizeCount, refetchOrders,
     logCommerceAction, logIntercomAction, logIntercomValue]);

  return (
    <BodyWrapper pageLayout={LayoutTypes.FullBleed}>
      <PageTitle title="Order Confirmation" />
      {/* Map grid on Desktop */}
      <div className={styles.mapGrid}>
        <div className={styles.mapWrapper}>
          { userLocation
              ? <GoogleMap address={getFormattedAddress(userLocation)} height={mapHeight} />
              : null
          }
        </div>

        <div className={styles.orderConfirmationWrap}>
          <div className={styles.orderSection}>
            <Header size={HeaderSize.Large} text="Order Confirmed" withStyle={{margin:'.75rem 0 0',fontSize:'1.5rem'}}>
              <div className={styles.orderSubHead}>
                <DeliveryCustomerDetails order={order} />
              </div>
            </Header>
          </div>

          <div className={styles.orderSection}>
            <div className={styles.sectionHdr}>Order Details</div>
            <div className={styles.sectionContent}>
              {order &&
                <OrderDetails order={order} />
              }
            </div>
          </div>

          <div className={styles.orderSection}>
            <div className={styles.sectionHdr}>
              Delivery Overview
            </div>
            <div className={styles.sectionContent}>
              <WhatToExpect orderType={order?.type} />
            </div>
          </div>

          <div className={styles.orderSection}>
            <div className={styles.sectionHdr}>Products</div>
            <ul className={styles.cartItems}>
              { order?.items?.map(item => (
                  <OrderItem
                    key={item.id}
                    product={item}
                    quantity={item.quantity_requested} />
              ))}
            </ul>
          </div>
          <div className={styles.buttonWrap}>
            <Button
              isCentered
              text="Return to Home Page"
              handleClick={() => navigateTop('/')} />
          </div>
          {/* surveys etc. */}
          <AdditionalCustomerAsks order={order} />
          {/* displays calc diffs in dev only */}
          <ValidateOrderResults costCalcDiffs={costCalcDiffs} />
        </div>
      </div> {/* close map grid */}
    </BodyWrapper>
  )
}

export default OrderConfirmation;
