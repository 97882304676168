import React from 'react';

import { useAnalyticsStore } from '../../App';
import { UserContext } from '../providers/UserProvider';
import { slotDiscountForLocation } from './timeslot-utils';
import { DiscountAndCreditContext } from '../providers/DiscountAndCreditProvider';
import useCurrentTimeSlots, { STORE_NOT_OPEN_YET } from './useCurrentTimeSlots';
import { CurrentTimeContext } from '../timeSlots/CurrentTimeProvider';
import { daysOfWeek } from '../util/date-utils';
import { getDeliveryWindowLabel } from '../deliveryWindows/scheduled-delivery-utils';
import { getOrderDate } from './timeslot-utils';
import SelectDayCarousel from './SelectDayCarousel';
import SelectTimeSlot from './SelectTimeSlot';
import StoreNotOpenBanner from '../dispensary/StoreNotOpenBanner';
import Spinner from '../common/Spinner';

import PropTypes from 'prop-types';

import styles from './SelectTimeSlot.module.css';

const DisplayDeliverySlots = ({
  dispensaryName,
  selectedDeliverySlot,
  setSelectedDeliverySlot,
  timeSlotsByDay
}) => {

  const { trackEvent } = useAnalyticsStore();
  const { location } = React.useContext(UserContext);
  const { setWindowDiscountCents } = React.useContext(DiscountAndCreditContext);

  // Time to use when deterimining what to display
  const currentTimeMS = React.useContext(CurrentTimeContext);
  const dayOfWeek = new Date(currentTimeMS).getDay();

  // Valid timeSlots to display
  const { defaultSlot, displayTimeSlots } = useCurrentTimeSlots(timeSlotsByDay);

  // The indexes used to build the timeslot JSON and pass to parent
  const [dayIdx, setDayIdx] = React.useState(0);
  const [slotIdx, setSlotIdx] = React.useState();
  // The current day (tab) we're viewing
  const [displayDayIdx, setDisplayDayIdx] = React.useState(0);

  // Set the default/updated selection in parent state.
  const updateSelectedSlot = React.useCallback((slotIdx, dayIdx) => {
    const timeSlots = displayTimeSlots[dayIdx];
    if (timeSlots) {
      const { hours, allows_orders_up_until_minutes_before_end, incentive } = timeSlots[slotIdx];
      // set date for calcing date plus future offset
      const orderDate = new Date();
      orderDate.setDate(orderDate.getDate() + dayIdx);
      const date = {
        month: orderDate.getMonth() + 1,
        day: orderDate.getDate(),
        year: orderDate.getYear() + 1900
      };
      // NOTE: This is the Slot Dto we submit!
      setSelectedDeliverySlot({
        dayIdx,
        slotIdx,
        date,
        hours,
        allows_orders_up_until_minutes_before_end,
        incentive
      });
      const usa_cents_discount = slotDiscountForLocation(incentive, location);
      setWindowDiscountCents(usa_cents_discount);
    }
  }, [displayTimeSlots, setSelectedDeliverySlot, setWindowDiscountCents, location]);

  const handleDayClick = (dayIdx) => {
    trackEvent(`select_day_checkout_click`);
    setDisplayDayIdx(dayIdx);
  }

  const handleSlotSelect = (slotIdx) => {
    // update UI
    setDayIdx(displayDayIdx);
    setSlotIdx(slotIdx);
    updateSelectedSlot(slotIdx, displayDayIdx);
  };

  const getDayLabel = (offset) => {
    if (offset === 0) {
      return 'TODAY';
    }
    const weekdayNum = (dayOfWeek + offset) % 7;
    const label = daysOfWeek[weekdayNum];
    return label.charAt(0).toUpperCase() + label.slice(1);
  };

  // Set initial default slot
  React.useEffect(() => {
    if (displayTimeSlots?.length &&
        typeof defaultSlot === "number" &&
        typeof slotIdx === "undefined") {
      // We've abused the time slot object by appending slotIdx/dayIdx data to it,
      // for UI purposes. The API will ignore this data
      if (selectedDeliverySlot) {
        setSlotIdx(selectedDeliverySlot.slotIdx);
        setDayIdx(selectedDeliverySlot.dayIdx);
        setDisplayDayIdx(selectedDeliverySlot.dayIdx);
        const { incentive={} } = selectedDeliverySlot;
        const usa_cents_discount = slotDiscountForLocation(incentive, location);
        setWindowDiscountCents(usa_cents_discount);
      } else if (typeof defaultSlot === 'number') {
        // No available same-day slots
        if (defaultSlot === -1) {
          setSlotIdx(0);
          setDayIdx(1);
          setDisplayDayIdx(1);
          updateSelectedSlot(0, 1);
        // Handle store not open yet, ordering allowed only during biz hours
        } else if (defaultSlot === STORE_NOT_OPEN_YET) {
          setSlotIdx(STORE_NOT_OPEN_YET);
        } else {
          setSlotIdx(defaultSlot);
          setDayIdx(0);
          setDisplayDayIdx(0);
          updateSelectedSlot(defaultSlot, 0);
        }
      }
    } // , currentTimeMS ?
  }, [defaultSlot, selectedDeliverySlot, updateSelectedSlot,
      slotIdx, displayTimeSlots, setWindowDiscountCents,
      location ]);

  return (
    <div className={styles.timeslotWrap}>
      { typeof slotIdx === 'number' && (
         /* Same-day ordering only --past store closing */
         (displayTimeSlots?.length === 1 && dayIdx === 1) ||
         /* Same-day ordering only --store not open yet */
         (displayTimeSlots?.length === 0 && slotIdx === STORE_NOT_OPEN_YET))
        ? <StoreNotOpenBanner dispensaryName={dispensaryName} />
        : typeof slotIdx === 'number' && displayTimeSlots.length > 0
          ? <>
              <div className={styles.selectedSlotLabel}>
                <span>{`${getDayLabel(dayIdx)}, ${getOrderDate(dayIdx, currentTimeMS)}, ${getDeliveryWindowLabel(displayTimeSlots[dayIdx][slotIdx].hours) }`}</span>
              </div>

              <SelectDayCarousel
                displayDayIdx={displayDayIdx}
                displayDayLabelIdx={dayOfWeek}
                timeSlotsByDay={displayTimeSlots}
                currentTimeMS={currentTimeMS}
                handleDayClick={handleDayClick} />

              <SelectTimeSlot
                dayIdx={dayIdx}
                slotIdx={slotIdx}
                displayDayIdx={displayDayIdx}
                timeSlots={displayTimeSlots[displayDayIdx]}
                currentTimeMS={currentTimeMS}
                handleSelect={handleSlotSelect} />
            </>
          : <Spinner />
      }
    </div>
  )
}

DisplayDeliverySlots.propTypes = {
  dispensaryName: PropTypes.string.isRequired
};

export default DisplayDeliverySlots;
