import * as React from 'react';

import { ModalContext, ModalType } from '../modal/ModalProvider';
import { CartItemsContext } from '../checkout/CartItemsProvider';
import { categoryTaglines } from './carouselCategoryText';
import { trackingString, partnerLabel } from '../analytics/tracking';
import { useAnalyticsStore } from '../../App';
import Header, { HeaderSize } from '../styleguide/Header';
import DisplayCurrentSlotETACompact from '../timeSlots/DisplayCurrentSlotETACompact';
import DisplayNextDiscountedSlot from '../timeSlots/DisplayNextDiscountedSlot';
import SeeAllButton from '../styleguide/DetourButton';
import SeeMoreCard from './carousel/SeeMoreCard';
import AddedToCartCheck from '../products/AddedToCartCheck';
import ProductPreviewCard from '../products/ProductPreviewCard';
import SimpleCarousel, { CarouselStyle } from '../common/SimpleCarousel';
import PropTypes from 'prop-types';

import styles from './ProductCarousel.module.css';

export const MAX_CAROUSEL_PRODUCTS = 25;

/**
 * This component will display a carousel of category-specific products for a dispensary

 */
const ProductCarousel = ({
  carouselTitle,
  categoryCount=0, // superset count!, not the carousel item count
  carouselProducts=[],
  allRelatedProducts,  // used for suggested products: modal to modal display
  carouselStyle=CarouselStyle.PRODUCT_CARDS,
  seeAllClick,
  withMoreCard,
  trackingPrefix,
  isSuggestedProduct,
  isSponsored,
  isReOrder,
}) => {

  const { trackEvent } = useAnalyticsStore();
  const { addItem } = React.useContext(CartItemsContext);
  const { displayModal } = React.useContext(ModalContext);
  const [showAddedCheck, setShowAddedCheck] = React.useState();
  const [moreItemsCount, setMoreItemsCount] = React.useState();

  // For (shop_mdl_)p12345_edibl(_item_clk) etc. (max: 30 CHAR!)
  const contextTrackingLabel = React.useCallback((product) => {
    let context;
    // TODO: Migrate all carousels to this
    if (trackingPrefix) {
      context = `${trackingPrefix}_`;
    } else {
      context = isReOrder ? 'reord_' : partnerLabel(product, isSponsored);
    }
    const { name:catName } = product.display_info.category;
    const categoryLabel = trackingString(catName).substring(0,5);
    return `${context}${categoryLabel}`;
  }, [trackingPrefix, isReOrder, isSponsored]);

  // For (shop_)csl_p12345_edibl_item_clk etc. (which is the max: 30 CHAR!)
  const carouselTrackingLabel = React.useCallback((product, action) => {
    const contextLabel = contextTrackingLabel(product);
    return `csl_${contextLabel}_${action}`;
  }, [contextTrackingLabel]);

  // This is the Quick Add carting only
  const handleAddToCart = React.useCallback((productId, productObj, quantity) => {
    trackEvent(carouselTrackingLabel(productObj, 'cart_clk'));
    setShowAddedCheck(true);
    addItem(productId, productObj, quantity);
  }, [trackEvent, carouselTrackingLabel, addItem]);

  const [itemProps, setItemProps] = React.useState();

  React.useEffect(() => {
    const getPropArray = (products) => {
      const propArray = [];
      products.forEach(product => {
        propArray.push({
          itemKey: product.id,
          Id: product.id,
          displayInfo: product.display_info,
          discount_data: product.discount_data,
          quantity_available: product.quantity_available,
          display_sold_out_status: product.display_sold_out_status,
          per_order_max_quantity: product.per_order_max_quantity,
          bundle_deal_data: product.bundle_deal_data,
          tags: product.tags,
          handleClick: () => {
              trackEvent(carouselTrackingLabel(product, 'item_clk'));
              const modalType = carouselStyle === CarouselStyle.SUGGESTED_PRODUCTS
                ? ModalType.SUGGESTED_PRODUCT
                : ModalType.PRODUCT;
              displayModal(modalType, {
                itemId: product.id,
                title: product.display_info.name,
                categoryName: product.display_info.category.display_name,
                trackingLabel: contextTrackingLabel(product),  // display/click handled later
                relatedProductsCarouselTitle: carouselTitle,  // Product Modal to Product Modal
                relatedProducts: allRelatedProducts || carouselProducts,  // allRelatedProducts is also for Modal to Modal
              });
          },
          handleCartClick: (quantity) => {
            handleAddToCart(product.id, product, quantity);
            // Quick add card button
            trackEvent(carouselTrackingLabel(product, 'add_clk'));
          },
          isSuggestedProduct
        });
      });
      return propArray;
    }
    if(!itemProps) {
      setItemProps(getPropArray(carouselProducts));
      setMoreItemsCount(Math.max(categoryCount, carouselProducts.length) - MAX_CAROUSEL_PRODUCTS);
    }
  }, [trackEvent, carouselTrackingLabel, contextTrackingLabel,
      carouselStyle, handleAddToCart, displayModal, itemProps,
      carouselTitle, carouselProducts, allRelatedProducts,
      categoryCount, isSuggestedProduct, isSponsored]);

  const [tagline, setTagline ] = React.useState();
  const [seeAllLabel, setSeeAllLabel] = React.useState();

  // Set See All text and tag line
  React.useEffect(() => {
    setSeeAllLabel(`${carouselTitle} (${categoryCount})`);
    if (categoryTaglines[carouselTitle]) {
      setTagline(categoryTaglines[carouselTitle]);
    }
  },[categoryCount, carouselTitle])

  // Used to defer product image load for offscreen product cards
  const [carouselHasScrolled, setCarouselHasScrolled] = React.useState();

  const handleSeeAllClick = React.useCallback((trackingLabel) => {
    if (seeAllClick) {
      seeAllClick();
      trackEvent(trackingLabel);
    }
  }, [seeAllClick, trackEvent]);

  return ( carouselProducts.length
    ? <>
        <div className={styles.sectionHeader}>
          <div className={styles.headerAndTagline}>
            <Header size={HeaderSize.Medium_Static} withStyle={{ margin:0 }}>
              { [CarouselStyle.SUGGESTED_PRODUCTS,
                 CarouselStyle.CART_SUGGESTED_PRODUCTS].includes(carouselStyle)
                ? <span className={styles.suggestedHeader}>{carouselTitle}</span>
                : <span className={styles.categoryName}>{carouselTitle}</span>
              }
            </Header>
            { carouselStyle !== CarouselStyle.SUGGESTED_PRODUCTS &&
              <div className={styles.slotCallouts}>
                <DisplayCurrentSlotETACompact />
                <DisplayNextDiscountedSlot />
              </div>
            }
            { tagline &&
              <div className={styles.sectionTagline}>{tagline}</div>
            }
          </div>
          {/* No see all link on suggested carsousel */}
          { seeAllClick &&
            <div className={styles.seeAllLink} onClick={() => handleSeeAllClick('see_more_lnk_clk')}>
              View All <span className={styles.viewAllDetails}>{seeAllLabel}</span>
            </div>
          }
        </div>

        { itemProps?.length &&
          <>
            <SimpleCarousel
              CarouselComponent={ProductPreviewCard}
              carouselItemProps={itemProps}
              maxItemCount={MAX_CAROUSEL_PRODUCTS}
              trackingLabel="home_products"
              carouselStyle={carouselStyle}
              MoreItemsCard={
                withMoreCard && moreItemsCount > 0
                  ? <SeeMoreCard
                      moreLabel={carouselTitle}
                      moreCount={moreItemsCount}
                      totalCount={categoryCount}
                      onClick={seeAllClick} />
                  : null
              }
              isSponsored={isSponsored}
              onCarouselScroll={() => setCarouselHasScrolled(true)}
              carouselHasScrolled={carouselHasScrolled} />

              { seeAllClick &&
                <div className={styles.seeAllButtonWrap}>
                  <SeeAllButton
                    text={`View All ${seeAllLabel}`}
                    isCentered
                    withStyle={{width:'calc(100% - 2rem)', margin:'0 1rem', padding:'.5rem .75rem'}}
                    handleClick={() => handleSeeAllClick('see_more_btn_clk')} />
                </div>
              }
          </>
        }
        { showAddedCheck &&
          <AddedToCartCheck
            key={`added_chk_${carouselTitle}`}
            closeFn={() => {
              setShowAddedCheck(false);
            }}
            closeMs={2000}
          />
        }
      </>
    : null
  );
}

ProductCarousel.propTypes = {
  carouselTitle: PropTypes.string.isRequired,
  categoryCount: PropTypes.number.isRequired,
  carouselProducts: PropTypes.array.isRequired,
  allRelatedProducts: PropTypes.array,
  carouselStyle: PropTypes.string,
  seeAllClick: PropTypes.func,
  withMoreCard: PropTypes.bool,
  trackingPrefix: PropTypes.string,
  isSuggestedProduct: PropTypes.bool,
  isSponsored: PropTypes.bool,
  isReOrder: PropTypes.bool,
};

export default ProductCarousel;

/**
 * Sample usages:
 *
 * ../products/SuggestedProductsCarousel.jsx
 * ../home/PreviewProducts.jsx
 * ../home/SubCategoryCarousels.jsx
 * ../reorder/ReOrderCarousel.jsx
 * ../brand/BrandCarousels.jsx
 * ../deals/SaleItemCarousels.jsx
 * ../suggestedProducts/CartSuggestedProductsCarousel
 *
 */
